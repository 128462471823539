//
export function setSliderWidth1(slider_block_class) {
    const sliderBlock = document.querySelector(slider_block_class);
    const sliderBlockWidth = sliderBlock.offsetWidth;
    let num;
    if (sliderBlockWidth < 575) num = 2
    else if (sliderBlockWidth < 768) num = 3
    else if (sliderBlockWidth < 1024) num = 4
    else num = 5;
    const owlItems = sliderBlock.querySelectorAll('.owl-item');
    const owlWrapper = sliderBlock.querySelector('.owl-wrapper');
    owlItems.forEach(card => card.style.width = `${(sliderBlockWidth)/num}px`);
    owlWrapper.style.width = `${owlItems.length*(sliderBlockWidth)/num}px`;
    owlWrapper.style.transform = "translateX(0px)";
}
//
/*
export function enableSwipe(slider_block_class) {
    const sliderBlock = document.querySelector(slider_block_class);
    const hammer = new Hammer(sliderBlock);
    hammer.on('swipeleft', function() {
        slideLeft(slider_block_class);
    });
    hammer.on('swiperight', function() {
        slideRight(slider_block_class);
    });
}
*/
export function enableSwipe(slider_block_class) {
    const sliderBlock = document.querySelector(slider_block_class);
    let touchStartX = 0;
    let touchEndX = 0;

    // Начало касания
    sliderBlock.addEventListener('touchstart', function(event) {
        touchStartX = event.changedTouches[0].screenX;
    });

    // Окончание касания
    sliderBlock.addEventListener('touchend', function(event) {
        touchEndX = event.changedTouches[0].screenX;
        handleSwipe();
    });

    function handleSwipe() {
        if (touchEndX < touchStartX) {
            slideLeft(slider_block_class);
        }
        if (touchEndX > touchStartX) {
            slideRight(slider_block_class);
        }
    }
}
//
function slideLeft(slider_block_class) {
    const sliderBlock = document.querySelector(slider_block_class);
    const owlWrapper = sliderBlock.querySelector('.owl-wrapper');
    const owlItems = sliderBlock.querySelectorAll('.owl-item');
    const sliderBlockWidth = sliderBlock.offsetWidth;
    const transformValue = getTransformValue(owlWrapper);
    const maxOffset = -(owlWrapper.offsetWidth - sliderBlockWidth);
    let newTransformValue = transformValue - sliderBlockWidth;
    if (newTransformValue < maxOffset) {
        newTransformValue = 0; // Reset to beginning for looping
    }
    owlWrapper.style.transform = `translateX(${newTransformValue}px)`;
}
//
function slideRight(slider_block_class) {
    const sliderBlock = document.querySelector(slider_block_class);
    const owlWrapper = sliderBlock.querySelector('.owl-wrapper');
    const owlItems = sliderBlock.querySelectorAll('.owl-item');
    const sliderBlockWidth = sliderBlock.offsetWidth;
    const transformValue = getTransformValue(owlWrapper);
    let newTransformValue = transformValue + sliderBlockWidth;
    if (newTransformValue > 0) {
        newTransformValue = -(owlWrapper.offsetWidth - sliderBlockWidth); // Reset to end for looping
    }
    owlWrapper.style.transform = `translateX(${newTransformValue}px)`;
}
//
function getTransformValue(element) {
    const style = window.getComputedStyle(element);
    const matrix = new WebKitCSSMatrix(style.transform);
    return matrix.m41;
}